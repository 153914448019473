/*
  This file was created years ago.
  Since then, const enums have largely been considered a mistake by Typescript because
  they don't bundle well & changes to const enums require re-transpiling every single file.
  Today, we get around this by compiling const enums to enums, which doesn't save as much space
  But makes life easier in development.

  Before adding a new enum, please consider the following:
  - Are the values easy to read? If so, a string union might be a better choice
  - Does the value live on the server? Then get it there (usually via a generated GraphQL type) instead of creating a second source of truth
*/
export var AppBar; (function (AppBar) {
  const HEIGHT = 56; AppBar[AppBar["HEIGHT"] = HEIGHT] = "HEIGHT";
})(AppBar || (AppBar = {}));

export var AuthIdentityTypeEnum; (function (AuthIdentityTypeEnum) {
  const LOCAL = 'LOCAL'; AuthIdentityTypeEnum["LOCAL"] = LOCAL;
  const GOOGLE = 'GOOGLE'; AuthIdentityTypeEnum["GOOGLE"] = GOOGLE;
  const MICROSOFT = 'MICROSOFT'; AuthIdentityTypeEnum["MICROSOFT"] = MICROSOFT;
})(AuthIdentityTypeEnum || (AuthIdentityTypeEnum = {}));

// https://github.com/material-components/material-components-web/blob/4844330e7836d9dc97798b47594ff0dbaac51227/packages/mdc-animation/_variables.scss
export var BezierCurve; (function (BezierCurve) {
  const DECELERATE = 'cubic-bezier(0, 0, .2, 1)'; BezierCurve["DECELERATE"] = DECELERATE;

  // Timing function to quickly accelerate and slowly decelerate
  const STANDARD_CURVE = 'cubic-bezier(.4, 0, .2, 1)'; BezierCurve["STANDARD_CURVE"] = STANDARD_CURVE;

  // Timing function to accelerate
  const ACCELERATE = 'cubic-bezier(.4, 0, 1, 1)'; BezierCurve["ACCELERATE"] = ACCELERATE;

  // Timing function to quickly accelerate and decelerate
  const SHARP_CURVE = 'cubic-bezier(.4, 0, .6, 1)'; BezierCurve["SHARP_CURVE"] = SHARP_CURVE;
})(BezierCurve || (BezierCurve = {}));

export var Breakpoint; (function (Breakpoint) {
  const SIDEBAR_LEFT = 1024; Breakpoint[Breakpoint["SIDEBAR_LEFT"] = SIDEBAR_LEFT] = "SIDEBAR_LEFT";
  const NEW_MEETING_GRID = 1112; Breakpoint[Breakpoint["NEW_MEETING_GRID"] = NEW_MEETING_GRID] = "NEW_MEETING_GRID";
  const NEW_MEETING_SELECTOR = 500; Breakpoint[Breakpoint["NEW_MEETING_SELECTOR"] = NEW_MEETING_SELECTOR] = "NEW_MEETING_SELECTOR";
  const POKER_DISCUSSION_FULLSCREEN_DRAWER = 660; Breakpoint[Breakpoint["POKER_DISCUSSION_FULLSCREEN_DRAWER"] = POKER_DISCUSSION_FULLSCREEN_DRAWER] = "POKER_DISCUSSION_FULLSCREEN_DRAWER";
  const SINGLE_REFLECTION_COLUMN = 704; Breakpoint[Breakpoint["SINGLE_REFLECTION_COLUMN"] = SINGLE_REFLECTION_COLUMN] = "SINGLE_REFLECTION_COLUMN"; // (ReflectionWith + 16) * 2,
  const DASH_BREAKPOINT_WIDEST = 1816; Breakpoint[Breakpoint["DASH_BREAKPOINT_WIDEST"] = DASH_BREAKPOINT_WIDEST] = "DASH_BREAKPOINT_WIDEST"; // (4*296) + (5*24) + (256*2) = 4 card cols, 4 col gutters, 2 sidebars
  const VOTE_PHASE = 800; Breakpoint[Breakpoint["VOTE_PHASE"] = VOTE_PHASE] = "VOTE_PHASE";
  const FUZZY_TABLET = 700; Breakpoint[Breakpoint["FUZZY_TABLET"] = FUZZY_TABLET] = "FUZZY_TABLET";
  const ORG_DRAWER = 1680; Breakpoint[Breakpoint["ORG_DRAWER"] = ORG_DRAWER] = "ORG_DRAWER";
})(Breakpoint || (Breakpoint = {}));

export var Card; (function (Card) {
  const BACKGROUND_COLOR = '#FFFFFF'; Card["BACKGROUND_COLOR"] = BACKGROUND_COLOR;
  const BORDER_RADIUS = 4; Card[Card["BORDER_RADIUS"] = BORDER_RADIUS] = "BORDER_RADIUS";
  const BUTTON_HEIGHT = 24; Card[Card["BUTTON_HEIGHT"] = BUTTON_HEIGHT] = "BUTTON_HEIGHT";
  const FONT_SIZE = 14; Card[Card["FONT_SIZE"] = FONT_SIZE] = "FONT_SIZE";
  const LINE_HEIGHT = '20px'; Card["LINE_HEIGHT"] = LINE_HEIGHT;
  const PADDING = '16px'; Card["PADDING"] = PADDING;
})(Card || (Card = {}));

export var ContactInfo; (function (ContactInfo) {
  const TELEPHONE = '612-227-5673'; ContactInfo["TELEPHONE"] = TELEPHONE;
  const EMAIL_BILLING = 'billing@parabol.co'; ContactInfo["EMAIL_BILLING"] = EMAIL_BILLING;
  const EMAIL_LOVE = 'love@parabol.co'; ContactInfo["EMAIL_LOVE"] = EMAIL_LOVE;
})(ContactInfo || (ContactInfo = {}));

// TODO refactor into ElementWidth
export var DashTimeline; (function (DashTimeline) {
  const FEED_MAX_WIDTH = 600; DashTimeline[DashTimeline["FEED_MAX_WIDTH"] = FEED_MAX_WIDTH] = "FEED_MAX_WIDTH";
  const FEED_MIN_WIDTH = 296; DashTimeline[DashTimeline["FEED_MIN_WIDTH"] = FEED_MIN_WIDTH] = "FEED_MIN_WIDTH";
  const MIN_PADDING = 16; DashTimeline[DashTimeline["MIN_PADDING"] = MIN_PADDING] = "MIN_PADDING";
  const TIMELINE_DRAWER_WIDTH = 336; DashTimeline[DashTimeline["TIMELINE_DRAWER_WIDTH"] = TIMELINE_DRAWER_WIDTH] = "TIMELINE_DRAWER_WIDTH";
})(DashTimeline || (DashTimeline = {}));

export var DiscussionThreadEnum; (function (DiscussionThreadEnum) {
  const WIDTH = 360; DiscussionThreadEnum[DiscussionThreadEnum["WIDTH"] = WIDTH] = "WIDTH";
})(DiscussionThreadEnum || (DiscussionThreadEnum = {}));

export var DragAttribute; (function (DragAttribute) {
  const CONTROL_BAR_COVERABLE = 'data-control-bar-coverable'; DragAttribute["CONTROL_BAR_COVERABLE"] = CONTROL_BAR_COVERABLE;
  const DROPPABLE = 'data-droppable'; DragAttribute["DROPPABLE"] = DROPPABLE;
  const DROPZONE = 'data-dropzone'; DragAttribute["DROPZONE"] = DROPZONE;
})(DragAttribute || (DragAttribute = {}));

export var DroppableType; (function (DroppableType) {
  const TASK = 'task'; DroppableType["TASK"] = TASK;
  const REFLECTION = 'reflection'; DroppableType["REFLECTION"] = REFLECTION;
})(DroppableType || (DroppableType = {}));

export var Duration; (function (Duration) {
  const MENU_OPEN = 150; Duration[Duration["MENU_OPEN"] = MENU_OPEN] = "MENU_OPEN";
  const MENU_OPEN_MAX = 188; Duration[Duration["MENU_OPEN_MAX"] = MENU_OPEN_MAX] = "MENU_OPEN_MAX";
  const MODAL_OPEN = 200; Duration[Duration["MODAL_OPEN"] = MODAL_OPEN] = "MODAL_OPEN";
  const PORTAL_CLOSE = 120; Duration[Duration["PORTAL_CLOSE"] = PORTAL_CLOSE] = "PORTAL_CLOSE";
  const SELECTION_CONTROL = 100; Duration[Duration["SELECTION_CONTROL"] = SELECTION_CONTROL] = "SELECTION_CONTROL";
  const TOOLTIP_DELAY = 400; Duration[Duration["TOOLTIP_DELAY"] = TOOLTIP_DELAY] = "TOOLTIP_DELAY";
  const TOOLTIP_OPEN = 150; Duration[Duration["TOOLTIP_OPEN"] = TOOLTIP_OPEN] = "TOOLTIP_OPEN";
  const TOOLTIP_CLOSE = 75; Duration[Duration["TOOLTIP_CLOSE"] = TOOLTIP_CLOSE] = "TOOLTIP_CLOSE";
})(Duration || (Duration = {}));

export var ElementWidth; (function (ElementWidth) {
  const CONTROL_BAR_BUTTON = 96; ElementWidth[ElementWidth["CONTROL_BAR_BUTTON"] = CONTROL_BAR_BUTTON] = "CONTROL_BAR_BUTTON";
  const CONTROL_BAR_PADDING = 8; ElementWidth[ElementWidth["CONTROL_BAR_PADDING"] = CONTROL_BAR_PADDING] = "CONTROL_BAR_PADDING";
  const BILLING_AVATAR = 44; ElementWidth[ElementWidth["BILLING_AVATAR"] = BILLING_AVATAR] = "BILLING_AVATAR";
  const DASHBOARD_AVATAR = 28; ElementWidth[ElementWidth["DASHBOARD_AVATAR"] = DASHBOARD_AVATAR] = "DASHBOARD_AVATAR";
  const DASHBOARD_AVATAR_OVERLAPPED = 20; ElementWidth[ElementWidth["DASHBOARD_AVATAR_OVERLAPPED"] = DASHBOARD_AVATAR_OVERLAPPED] = "DASHBOARD_AVATAR_OVERLAPPED";
  const REFLECTION_CARD = 296; ElementWidth[ElementWidth["REFLECTION_CARD"] = REFLECTION_CARD] = "REFLECTION_CARD";
  const REFLECTION_CARD_PADDED = 296; ElementWidth[ElementWidth["REFLECTION_CARD_PADDED"] = REFLECTION_CARD_PADDED] = "REFLECTION_CARD_PADDED";
  const REFLECTION_CARD_PADDING = 6; ElementWidth[ElementWidth["REFLECTION_CARD_PADDING"] = REFLECTION_CARD_PADDING] = "REFLECTION_CARD_PADDING";
  const REFLECTION_COLUMN = 320; ElementWidth[ElementWidth["REFLECTION_COLUMN"] = REFLECTION_COLUMN] = "REFLECTION_COLUMN";
  const NEW_MEETING_FAB = 128; ElementWidth[ElementWidth["NEW_MEETING_FAB"] = NEW_MEETING_FAB] = "NEW_MEETING_FAB";
  const MEETING_CARD = 320; ElementWidth[ElementWidth["MEETING_CARD"] = MEETING_CARD] = "MEETING_CARD";
  const MEETING_CARD_MARGIN = 16; ElementWidth[ElementWidth["MEETING_CARD_MARGIN"] = MEETING_CARD_MARGIN] = "MEETING_CARD_MARGIN";
  const MEETING_CARD_LARGE_MARGIN = 40; ElementWidth[ElementWidth["MEETING_CARD_LARGE_MARGIN"] = MEETING_CARD_LARGE_MARGIN] = "MEETING_CARD_LARGE_MARGIN";
  const MEETING_CARD_WITH_MARGIN = 336; ElementWidth[ElementWidth["MEETING_CARD_WITH_MARGIN"] = MEETING_CARD_WITH_MARGIN] = "MEETING_CARD_WITH_MARGIN";
  const PANEL_WIDTH = 976; ElementWidth[ElementWidth["PANEL_WIDTH"] = PANEL_WIDTH] = "PANEL_WIDTH";
})(ElementWidth || (ElementWidth = {}));

export var ElementHeight; (function (ElementHeight) {
  const DASHBOARD_AVATAR = 28; ElementHeight[ElementHeight["DASHBOARD_AVATAR"] = DASHBOARD_AVATAR] = "DASHBOARD_AVATAR";
  const MEETING_CARD_AVATARS = 32; ElementHeight[ElementHeight["MEETING_CARD_AVATARS"] = MEETING_CARD_AVATARS] = "MEETING_CARD_AVATARS";
  const REFLECTION_CARD = 44; ElementHeight[ElementHeight["REFLECTION_CARD"] = REFLECTION_CARD] = "REFLECTION_CARD";
  const REFLECTION_CARD_MAX = 104; ElementHeight[ElementHeight["REFLECTION_CARD_MAX"] = REFLECTION_CARD_MAX] = "REFLECTION_CARD_MAX"; // 4 lines (20px each) + (2 * 12px) vertical gutter
  const MEETING_CARD_IMG = 180; ElementHeight[ElementHeight["MEETING_CARD_IMG"] = MEETING_CARD_IMG] = "MEETING_CARD_IMG";
  const MEETING_CARD_MARGIN = 16; ElementHeight[ElementHeight["MEETING_CARD_MARGIN"] = MEETING_CARD_MARGIN] = "MEETING_CARD_MARGIN";
})(ElementHeight || (ElementHeight = {}));

export var ExternalLinks; (function (ExternalLinks) {
  const EMAIL_CDN = 'https://action-files.parabol.co/static/email/'; ExternalLinks["EMAIL_CDN"] = EMAIL_CDN;
  const LOGO = 'https://action-files.parabol.co/static/favicon-with-more-padding.jpeg'; ExternalLinks["LOGO"] = LOGO;
  const PRICING_LINK = 'https://www.parabol.co/pricing/'; ExternalLinks["PRICING_LINK"] = PRICING_LINK;
  const GETTING_STARTED_RETROS = 'https://www.parabol.co/resources/retrospective-meetings'; ExternalLinks["GETTING_STARTED_RETROS"] = GETTING_STARTED_RETROS;
  const GETTING_STARTED_RETRO_VIDEO = 'https://www.parabol.co/resources/retrospective-meetings/#video'; ExternalLinks["GETTING_STARTED_RETRO_VIDEO"] = GETTING_STARTED_RETRO_VIDEO;
  const GETTING_STARTED_CHECK_INS = 'https://www.parabol.co/resources/check-in-meetings'; ExternalLinks["GETTING_STARTED_CHECK_INS"] = GETTING_STARTED_CHECK_INS;
  const GETTING_STARTED_SPRINT_POKER = 'https://www.parabol.co/resources/sprint-poker-meetings'; ExternalLinks["GETTING_STARTED_SPRINT_POKER"] = GETTING_STARTED_SPRINT_POKER;
  const GETTING_STARTED_ASYNC_STANDUP = 'https://www.parabol.co/resources/async-standup-meetings'; ExternalLinks["GETTING_STARTED_ASYNC_STANDUP"] = GETTING_STARTED_ASYNC_STANDUP;
  const INTEGRATIONS = 'https://www.parabol.co/integrations'; ExternalLinks["INTEGRATIONS"] = INTEGRATIONS;
  const INTEGRATIONS_JIRA = 'https://www.parabol.co/integrations/jira'; ExternalLinks["INTEGRATIONS_JIRA"] = INTEGRATIONS_JIRA;
  const INTEGRATIONS_GITHUB = 'https://www.parabol.co/integrations/github'; ExternalLinks["INTEGRATIONS_GITHUB"] = INTEGRATIONS_GITHUB;
  const INTEGRATIONS_MATTERMOST = 'https://www.parabol.co/integrations/mattermost'; ExternalLinks["INTEGRATIONS_MATTERMOST"] = INTEGRATIONS_MATTERMOST;
  const INTEGRATIONS_GITLAB = 'https://www.parabol.co/integrations/gitlab'; ExternalLinks["INTEGRATIONS_GITLAB"] = INTEGRATIONS_GITLAB;
  const INTEGRATIONS_JIRASERVER = 'https://www.parabol.co/integrations/jira-server'; ExternalLinks["INTEGRATIONS_JIRASERVER"] = INTEGRATIONS_JIRASERVER;
  const INTEGRATIONS_SLACK = 'https://www.parabol.co/integrations/slack'; ExternalLinks["INTEGRATIONS_SLACK"] = INTEGRATIONS_SLACK;
  const INTEGRATIONS_MSTEAMS = 'https://www.parabol.co/integrations/msteams'; ExternalLinks["INTEGRATIONS_MSTEAMS"] = INTEGRATIONS_MSTEAMS;
  const INTEGRATIONS_SUPPORT_JIRA = 'https://www.parabol.co/integrations/jira'; ExternalLinks["INTEGRATIONS_SUPPORT_JIRA"] = INTEGRATIONS_SUPPORT_JIRA;
  const INTEGRATIONS_SUPPORT_JIRA_AUTHORIZATION = 'https://www.parabol.co/support/authorization-issues-jira-integration'; ExternalLinks["INTEGRATIONS_SUPPORT_JIRA_AUTHORIZATION"] = INTEGRATIONS_SUPPORT_JIRA_AUTHORIZATION;
  const INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_TEAM_MANAGED = 'https://www.parabol.co/support/estimation-in-jira-team'; ExternalLinks["INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_TEAM_MANAGED"] = INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_TEAM_MANAGED;
  const INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_COMPANY_MANAGED = 'https://www.parabol.co/support/estimation-in-jira-company'; ExternalLinks["INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_COMPANY_MANAGED"] = INTEGRATIONS_SUPPORT_JIRA_MISSING_FIELD_COMPANY_MANAGED;
  const INTEGRATIONS_SUPPORT_GITHUB = 'https://www.parabol.co/integrations/github'; ExternalLinks["INTEGRATIONS_SUPPORT_GITHUB"] = INTEGRATIONS_SUPPORT_GITHUB;
  const INTEGRATIONS_SUPPORT_MATTERMOST = 'https://www.parabol.co/integrations/mattermost'; ExternalLinks["INTEGRATIONS_SUPPORT_MATTERMOST"] = INTEGRATIONS_SUPPORT_MATTERMOST;
  const INTEGRATIONS_SUPPORT_GITLAB = 'https://www.parabol.co/integrations/gitlab'; ExternalLinks["INTEGRATIONS_SUPPORT_GITLAB"] = INTEGRATIONS_SUPPORT_GITLAB;
  const INTEGRATIONS_SUPPORT_JIRASERVER = 'https://www.parabol.co/integrations/jira-server'; ExternalLinks["INTEGRATIONS_SUPPORT_JIRASERVER"] = INTEGRATIONS_SUPPORT_JIRASERVER;
  const INTEGRATIONS_SUPPORT_MSTEAMS = 'https://www.parabol.co/integrations/msteams'; ExternalLinks["INTEGRATIONS_SUPPORT_MSTEAMS"] = INTEGRATIONS_SUPPORT_MSTEAMS;
  const INTEGRATIONS_SUPPORT_AZUREDEVOPS = 'https://www.parabol.co/integrations/azure-devops'; ExternalLinks["INTEGRATIONS_SUPPORT_AZUREDEVOPS"] = INTEGRATIONS_SUPPORT_AZUREDEVOPS;
  const INTEGRATIONS_SUPPORT_SLACK = 'https://www.parabol.co/integrations/slack'; ExternalLinks["INTEGRATIONS_SUPPORT_SLACK"] = INTEGRATIONS_SUPPORT_SLACK;
  const RESOURCES = 'https://www.parabol.co/resources'; ExternalLinks["RESOURCES"] = RESOURCES;
  const SUPPORT = 'https://www.parabol.co/support'; ExternalLinks["SUPPORT"] = SUPPORT;
  const CONTACT = 'https://www.parabol.co/contact'; ExternalLinks["CONTACT"] = CONTACT;
  const TEAM = 'https://www.parabol.co/team/'; ExternalLinks["TEAM"] = TEAM;
  const LANDING_PAGE = 'https://www.parabol.co/'; ExternalLinks["LANDING_PAGE"] = LANDING_PAGE;
})(ExternalLinks || (ExternalLinks = {}));

export var Filter; (function (Filter) {
  const BENEATH_DIALOG = 'blur(1.5px)'; Filter["BENEATH_DIALOG"] = BENEATH_DIALOG;
})(Filter || (Filter = {}));

export var GlobalBanner; (function (GlobalBanner) {
  const HEIGHT = 24; GlobalBanner[GlobalBanner["HEIGHT"] = HEIGHT] = "HEIGHT";
})(GlobalBanner || (GlobalBanner = {}));

export var Gutters; (function (Gutters) {
  const COLUMN_INNER_GUTTER = '12px'; Gutters["COLUMN_INNER_GUTTER"] = COLUMN_INNER_GUTTER;
  const DASH_GUTTER = '20px'; Gutters["DASH_GUTTER"] = DASH_GUTTER;
  const REFLECTION_INNER_GUTTER_HORIZONTAL = '16px'; Gutters["REFLECTION_INNER_GUTTER_HORIZONTAL"] = REFLECTION_INNER_GUTTER_HORIZONTAL;
  const REFLECTION_INNER_GUTTER_VERTICAL = '12px'; Gutters["REFLECTION_INNER_GUTTER_VERTICAL"] = REFLECTION_INNER_GUTTER_VERTICAL;
  const ROW_INNER_GUTTER = '12px'; Gutters["ROW_INNER_GUTTER"] = ROW_INNER_GUTTER;
})(Gutters || (Gutters = {}));

export var InvitationTokenError; (function (InvitationTokenError) {
  const NOT_FOUND = 'notFound'; InvitationTokenError["NOT_FOUND"] = NOT_FOUND;
  const EXPIRED = 'expired'; InvitationTokenError["EXPIRED"] = EXPIRED;
  const ALREADY_ACCEPTED = 'accepted'; InvitationTokenError["ALREADY_ACCEPTED"] = ALREADY_ACCEPTED;
  const NOT_SIGNED_IN = 'notSignedIn'; InvitationTokenError["NOT_SIGNED_IN"] = NOT_SIGNED_IN;
})(InvitationTokenError || (InvitationTokenError = {}));
export var InvoiceItemType; (function (InvoiceItemType) {
  const ADD_USER = 'addUser'; InvoiceItemType["ADD_USER"] = ADD_USER;
  const PAUSE_USER = 'pauseUser'; InvoiceItemType["PAUSE_USER"] = PAUSE_USER;
  const AUTO_PAUSE_USER = 'autoPauseUser'; InvoiceItemType["AUTO_PAUSE_USER"] = AUTO_PAUSE_USER;
  const REMOVE_USER = 'removeUser'; InvoiceItemType["REMOVE_USER"] = REMOVE_USER;
  const UNPAUSE_USER = 'unpauseUser'; InvoiceItemType["UNPAUSE_USER"] = UNPAUSE_USER;
})(InvoiceItemType || (InvoiceItemType = {}));

// https://material.io/design/layout/spacing-methods.html
// NOTE: iterate on this pattern as we go (TA)
export var Layout; (function (Layout) {
  const TYPE_GRID = 4; Layout[Layout["TYPE_GRID"] = TYPE_GRID] = "TYPE_GRID"; // .5x
  const LAYOUT_GRID = 8; Layout[Layout["LAYOUT_GRID"] = LAYOUT_GRID] = "LAYOUT_GRID"; // 1x
  const ROW_GUTTER = 16; Layout[Layout["ROW_GUTTER"] = ROW_GUTTER] = "ROW_GUTTER"; // 2x
  const SETTINGS_MAX_WIDTH = 768; Layout[Layout["SETTINGS_MAX_WIDTH"] = SETTINGS_MAX_WIDTH] = "SETTINGS_MAX_WIDTH";
  const SETTINGS_NARROW_MAX_WIDTH = 644; Layout[Layout["SETTINGS_NARROW_MAX_WIDTH"] = SETTINGS_NARROW_MAX_WIDTH] = "SETTINGS_NARROW_MAX_WIDTH";
  const TASK_COLUMNS_MAX_WIDTH = 1360; Layout[Layout["TASK_COLUMNS_MAX_WIDTH"] = TASK_COLUMNS_MAX_WIDTH] = "TASK_COLUMNS_MAX_WIDTH";
})(Layout || (Layout = {}));

export var LoaderSize; (function (LoaderSize) {
  const MENU = 24; LoaderSize[LoaderSize["MENU"] = MENU] = "MENU";
  const MAIN = 40; LoaderSize[LoaderSize["MAIN"] = MAIN] = "MAIN";
  const WHOLE_PAGE = 400; LoaderSize[LoaderSize["WHOLE_PAGE"] = WHOLE_PAGE] = "WHOLE_PAGE";
  const PANEL = 200; LoaderSize[LoaderSize["PANEL"] = PANEL] = "PANEL";
})(LoaderSize || (LoaderSize = {}));

export var LocalStorageKey; (function (LocalStorageKey) {
  const APP_TOKEN_KEY = 'Action:token'; LocalStorageKey["APP_TOKEN_KEY"] = APP_TOKEN_KEY;
  const INVITATION_TOKEN = 'invitationToken'; LocalStorageKey["INVITATION_TOKEN"] = INVITATION_TOKEN;
  const GRAPHIQL_SCHEMA = 'gqlSchema'; LocalStorageKey["GRAPHIQL_SCHEMA"] = GRAPHIQL_SCHEMA;
  const EMAIL = 'email'; LocalStorageKey["EMAIL"] = EMAIL;
  const ERROR_PRONE_AT = 'errorProneAt'; LocalStorageKey["ERROR_PRONE_AT"] = ERROR_PRONE_AT;
})(LocalStorageKey || (LocalStorageKey = {}));

export var LOCKED_MESSAGE; (function (LOCKED_MESSAGE) {
  const TEAM_INVITE = `Sorry! You're unable to join this team because one of your teams has an overdue payment`; LOCKED_MESSAGE[LOCKED_MESSAGE["TEAM_INVITE"] = TEAM_INVITE] = "TEAM_INVITE";
})(LOCKED_MESSAGE || (LOCKED_MESSAGE = {}));

export var AuthenticationError; (function (AuthenticationError) {
  const FAILED_TO_SEND = 'failedToSend'; AuthenticationError["FAILED_TO_SEND"] = FAILED_TO_SEND;
  const MISSING_HASH = 'missingHash'; AuthenticationError["MISSING_HASH"] = MISSING_HASH;
  const INVALID_PASSWORD = 'invalidPassword'; AuthenticationError["INVALID_PASSWORD"] = INVALID_PASSWORD;
  const IDENTITY_NOT_FOUND = 'identityNotFound'; AuthenticationError["IDENTITY_NOT_FOUND"] = IDENTITY_NOT_FOUND;
  const EXCEEDED_RESET_THRESHOLD = 'exceededResetThreshold'; AuthenticationError["EXCEEDED_RESET_THRESHOLD"] = EXCEEDED_RESET_THRESHOLD;
  const USER_NOT_FOUND = 'userNotFound'; AuthenticationError["USER_NOT_FOUND"] = USER_NOT_FOUND;
  const USER_EXISTS_GOOGLE = 'userExistsGoogle'; AuthenticationError["USER_EXISTS_GOOGLE"] = USER_EXISTS_GOOGLE;
  const USER_EXISTS_MICROSOFT = 'userExistsMicrosoft'; AuthenticationError["USER_EXISTS_MICROSOFT"] = USER_EXISTS_MICROSOFT;
  const USER_EXISTS_SAML = 'userExistsSaml'; AuthenticationError["USER_EXISTS_SAML"] = USER_EXISTS_SAML;
})(AuthenticationError || (AuthenticationError = {}));

export var MathEnum; (function (MathEnum) {
  const MAX_INT = 2147483647; MathEnum[MathEnum["MAX_INT"] = MAX_INT] = "MAX_INT";
})(MathEnum || (MathEnum = {}));

export var MeetingControlBarEnum; (function (MeetingControlBarEnum) {
  const HEIGHT = 56; MeetingControlBarEnum[MeetingControlBarEnum["HEIGHT"] = HEIGHT] = "HEIGHT";
})(MeetingControlBarEnum || (MeetingControlBarEnum = {}));

export var MeetingLabels; (function (MeetingLabels) {
  const TIME_LIMIT = 'Time Limit'; MeetingLabels["TIME_LIMIT"] = TIME_LIMIT;
  const TIMER = 'Timer'; MeetingLabels["TIMER"] = TIMER;
})(MeetingLabels || (MeetingLabels = {}));

export var PokerCards; (function (PokerCards) {
  const HEIGHT = 175; PokerCards[PokerCards["HEIGHT"] = HEIGHT] = "HEIGHT";
  const WIDTH = 125; PokerCards[PokerCards["WIDTH"] = WIDTH] = "WIDTH";
  const OVERLAP = 96; PokerCards[PokerCards["OVERLAP"] = OVERLAP] = "OVERLAP";
  const MAX_VALUE = 2147483647; PokerCards[PokerCards["MAX_VALUE"] = MAX_VALUE] = "MAX_VALUE";
  // the angle in degrees from the middle of the deck to a deck edge (0-90)
  const TILT = 8; PokerCards[PokerCards["TILT"] = TILT] = "TILT";
  // number of pixels from the middle of the circle below the fold to the middle of the card (0-2x innerHeight)
  const RADIUS = 1200; PokerCards[PokerCards["RADIUS"] = RADIUS] = "RADIUS";
  // the % of the first & last card that sits below the fold (0-1)
  const MAX_HIDDEN = 0.35; PokerCards[PokerCards["MAX_HIDDEN"] = MAX_HIDDEN] = "MAX_HIDDEN";
  const PASS_CARD = 'Pass'; PokerCards["PASS_CARD"] = PASS_CARD;
  const QUESTION_CARD = '?'; PokerCards["QUESTION_CARD"] = QUESTION_CARD;
  const AVATAR_WIDTH = 46; PokerCards[PokerCards["AVATAR_WIDTH"] = AVATAR_WIDTH] = "AVATAR_WIDTH";
  const AVATAR_BORDER = 3; PokerCards[PokerCards["AVATAR_BORDER"] = AVATAR_BORDER] = "AVATAR_BORDER";
})(PokerCards || (PokerCards = {}));
export var FilterLabels; (function (FilterLabels) {
  const ALL_TEAMS = 'All Teams'; FilterLabels["ALL_TEAMS"] = ALL_TEAMS;
  const ALL_TEAM_MEMBERS = 'All Team Members'; FilterLabels["ALL_TEAM_MEMBERS"] = ALL_TEAM_MEMBERS;
  const ALL_EVENTS = 'All Events'; FilterLabels["ALL_EVENTS"] = ALL_EVENTS;
})(FilterLabels || (FilterLabels = {}));

export var MeetingSettingsThreshold; (function (MeetingSettingsThreshold) {
  const RETROSPECTIVE_TOTAL_VOTES_DEFAULT = 5; MeetingSettingsThreshold[MeetingSettingsThreshold["RETROSPECTIVE_TOTAL_VOTES_DEFAULT"] = RETROSPECTIVE_TOTAL_VOTES_DEFAULT] = "RETROSPECTIVE_TOTAL_VOTES_DEFAULT";
  const RETROSPECTIVE_MAX_VOTES_PER_GROUP_DEFAULT = 3; MeetingSettingsThreshold[MeetingSettingsThreshold["RETROSPECTIVE_MAX_VOTES_PER_GROUP_DEFAULT"] = RETROSPECTIVE_MAX_VOTES_PER_GROUP_DEFAULT] = "RETROSPECTIVE_MAX_VOTES_PER_GROUP_DEFAULT";
  const RETROSPECTIVE_TOTAL_VOTES_MAX = 12; MeetingSettingsThreshold[MeetingSettingsThreshold["RETROSPECTIVE_TOTAL_VOTES_MAX"] = RETROSPECTIVE_TOTAL_VOTES_MAX] = "RETROSPECTIVE_TOTAL_VOTES_MAX";
})(MeetingSettingsThreshold || (MeetingSettingsThreshold = {}));

// equal to utils/constants
export var MeetingTypes; (function (MeetingTypes) {
  const ACTION = 'action'; MeetingTypes["ACTION"] = ACTION;
  const RETROSPECTIVE = 'retrospective'; MeetingTypes["RETROSPECTIVE"] = RETROSPECTIVE;
})(MeetingTypes || (MeetingTypes = {}));

export var NavSidebar; (function (NavSidebar) {
  const FONT_SIZE = 14; NavSidebar[NavSidebar["FONT_SIZE"] = FONT_SIZE] = "FONT_SIZE";
  const LEFT_BORDER_WIDTH = '3px'; NavSidebar["LEFT_BORDER_WIDTH"] = LEFT_BORDER_WIDTH;
  const LINE_HEIGHT = '22px'; NavSidebar["LINE_HEIGHT"] = LINE_HEIGHT;
  const SUB_FONT_SIZE = 14; NavSidebar[NavSidebar["SUB_FONT_SIZE"] = SUB_FONT_SIZE] = "SUB_FONT_SIZE";
  const SUB_LINE_HEIGHT = '22px'; NavSidebar["SUB_LINE_HEIGHT"] = SUB_LINE_HEIGHT;
  const WIDTH = 256; NavSidebar[NavSidebar["WIDTH"] = WIDTH] = "WIDTH";
})(NavSidebar || (NavSidebar = {}));

export var NewMeeting; (function (NewMeeting) {
  const ILLUSTRATION_WIDTH = 450; NewMeeting[NewMeeting["ILLUSTRATION_WIDTH"] = ILLUSTRATION_WIDTH] = "ILLUSTRATION_WIDTH";
  const CONTROLS_WIDTH = 300; NewMeeting[NewMeeting["CONTROLS_WIDTH"] = CONTROLS_WIDTH] = "CONTROLS_WIDTH";
})(NewMeeting || (NewMeeting = {}));
export var Pricing; (function (Pricing) {
  const PRO_SEAT_COST = 600; Pricing[Pricing["PRO_SEAT_COST"] = PRO_SEAT_COST] = "PRO_SEAT_COST";
})(Pricing || (Pricing = {}));

export var Providers; (function (Providers) {
  const ATLASSIAN_NAME = 'Atlassian'; Providers["ATLASSIAN_NAME"] = ATLASSIAN_NAME;
  const ATLASSIAN_DESC = 'Use Jira Cloud Issues from within Parabol.'; Providers["ATLASSIAN_DESC"] = ATLASSIAN_DESC;
  const JIRA_SERVER_NAME = 'Jira Data Center'; Providers["JIRA_SERVER_NAME"] = JIRA_SERVER_NAME;
  const JIRA_SERVER_DESC = 'Use Jira Data Center Issues from within Parabol.'; Providers["JIRA_SERVER_DESC"] = JIRA_SERVER_DESC;
  const GITHUB_NAME = 'GitHub'; Providers["GITHUB_NAME"] = GITHUB_NAME;
  const GCAL_NAME = 'Google Calendar'; Providers["GCAL_NAME"] = GCAL_NAME;
  const GCAL_DESC = 'Create Google Calendar events from within Parabol.'; Providers["GCAL_DESC"] = GCAL_DESC;
  const GITHUB_DESC = 'Use GitHub Issues from within Parabol.'; Providers["GITHUB_DESC"] = GITHUB_DESC;
  const GITHUB_SCOPE = 'read:org,repo'; Providers["GITHUB_SCOPE"] = GITHUB_SCOPE;
  const GITLAB_SCOPE = 'api'; Providers["GITLAB_SCOPE"] = GITLAB_SCOPE;
  const MATTERMOST_NAME = 'Mattermost'; Providers["MATTERMOST_NAME"] = MATTERMOST_NAME;
  const MATTERMOST_DESC = 'Push notifications to Mattermost.'; Providers["MATTERMOST_DESC"] = MATTERMOST_DESC;
  const SLACK_NAME = 'Slack'; Providers["SLACK_NAME"] = SLACK_NAME;
  const SLACK_DESC = 'Push notifications to Slack.'; Providers["SLACK_DESC"] = SLACK_DESC;
  const AZUREDEVOPS_NAME = 'Azure DevOps'; Providers["AZUREDEVOPS_NAME"] = AZUREDEVOPS_NAME;
  const AZUREDEVOPS_DESC = 'Use Azure DevOps Issues from within Parabol.'; Providers["AZUREDEVOPS_DESC"] = AZUREDEVOPS_DESC;
  const MSTEAMS_NAME = 'Microsoft Teams'; Providers["MSTEAMS_NAME"] = MSTEAMS_NAME;
  const MSTEAMS_DESC = 'Push notifications to Microsoft Teams'; Providers["MSTEAMS_DESC"] = MSTEAMS_DESC;
})(Providers || (Providers = {}));

// Use power of 2 for cheap sense of scale (e.g. 2, 4, 8)
export var Radius; (function (Radius) {
  const BUTTON_PILL = '10em'; Radius["BUTTON_PILL"] = BUTTON_PILL;
  const FIELD = 4; Radius[Radius["FIELD"] = FIELD] = "FIELD";
  const DIALOG = 8; Radius[Radius["DIALOG"] = DIALOG] = "DIALOG";
  const MENU = 4; Radius[Radius["MENU"] = MENU] = "MENU";
  const SNACKBAR = 4; Radius[Radius["SNACKBAR"] = SNACKBAR] = "SNACKBAR";
  const TOOLTIP = 2; Radius[Radius["TOOLTIP"] = TOOLTIP] = "TOOLTIP";
})(Radius || (Radius = {}));

export var ReflectionStackPerspective; (function (ReflectionStackPerspective) {
  const X = 8; ReflectionStackPerspective[ReflectionStackPerspective["X"] = X] = "X";
  const Y = 6; ReflectionStackPerspective[ReflectionStackPerspective["Y"] = Y] = "Y";
})(ReflectionStackPerspective || (ReflectionStackPerspective = {}));

export var RetroDemo; (function (RetroDemo) {
  const MEETING_ID = 'demoMeetingId'; RetroDemo["MEETING_ID"] = MEETING_ID;
  const TEAM_ID = 'demoTeamId'; RetroDemo["TEAM_ID"] = TEAM_ID;
  const REFLECT_STAGE_ID = 'reflectStage'; RetroDemo["REFLECT_STAGE_ID"] = REFLECT_STAGE_ID;
  const GROUP_STAGE_ID = 'groupStage'; RetroDemo["GROUP_STAGE_ID"] = GROUP_STAGE_ID;
  const VOTE_STAGE_ID = 'voteStage'; RetroDemo["VOTE_STAGE_ID"] = VOTE_STAGE_ID;
})(RetroDemo || (RetroDemo = {}));

export var RightSidebar; (function (RightSidebar) {
  const WIDTH = 256; RightSidebar[RightSidebar["WIDTH"] = WIDTH] = "WIDTH";
})(RightSidebar || (RightSidebar = {}));

export var Security; (function (Security) {
  const SALT_ROUNDS = 12; Security[Security["SALT_ROUNDS"] = SALT_ROUNDS] = "SALT_ROUNDS";
  const MASS_INVITATION_TOKEN_LENGTH = 12; Security[Security["MASS_INVITATION_TOKEN_LENGTH"] = MASS_INVITATION_TOKEN_LENGTH] = "MASS_INVITATION_TOKEN_LENGTH";
})(Security || (Security = {}));

export var ServerChannel; (function (ServerChannel) {
  const GQL_EXECUTOR_STREAM = 'gqlStream'; ServerChannel["GQL_EXECUTOR_STREAM"] = GQL_EXECUTOR_STREAM;
  const GQL_EXECUTOR_CONSUMER_GROUP = 'gqlConsumerGroup'; ServerChannel["GQL_EXECUTOR_CONSUMER_GROUP"] = GQL_EXECUTOR_CONSUMER_GROUP;
})(ServerChannel || (ServerChannel = {}));

export var SubscriptionChannel; (function (SubscriptionChannel) {
  const TASK = 'task'; SubscriptionChannel["TASK"] = TASK;
  const TEAM = 'team'; SubscriptionChannel["TEAM"] = TEAM;
  const MEETING = 'meeting'; SubscriptionChannel["MEETING"] = MEETING;
  const NOTIFICATION = 'notification'; SubscriptionChannel["NOTIFICATION"] = NOTIFICATION;
  const ORGANIZATION = 'organization'; SubscriptionChannel["ORGANIZATION"] = ORGANIZATION;
})(SubscriptionChannel || (SubscriptionChannel = {}));

export var TaskStatus; (function (TaskStatus) {
  const DONE = 'done'; TaskStatus["DONE"] = DONE;
  const ACTIVE = 'active'; TaskStatus["ACTIVE"] = ACTIVE;
  const STUCK = 'stuck'; TaskStatus["STUCK"] = STUCK;
  const FUTURE = 'future'; TaskStatus["FUTURE"] = FUTURE;
  const ARCHIVED = 'archived'; TaskStatus["ARCHIVED"] = ARCHIVED;
  const PRIVATE = 'private'; TaskStatus["PRIVATE"] = PRIVATE;
})(TaskStatus || (TaskStatus = {}));

export var TaskStatusLabel; (function (TaskStatusLabel) {
  const DONE = 'Done'; TaskStatusLabel["DONE"] = DONE;
  const ACTIVE = 'Active'; TaskStatusLabel["ACTIVE"] = ACTIVE;
  const STUCK = 'Stuck'; TaskStatusLabel["STUCK"] = STUCK;
  const FUTURE = 'Future'; TaskStatusLabel["FUTURE"] = FUTURE;
  const ARCHIVED = 'Archived'; TaskStatusLabel["ARCHIVED"] = ARCHIVED;
  const PRIVATE = 'Private'; TaskStatusLabel["PRIVATE"] = PRIVATE;
})(TaskStatusLabel || (TaskStatusLabel = {}));

export var TierLabel; (function (TierLabel) {
  const STARTER = 'Starter'; TierLabel["STARTER"] = STARTER;
  const TEAM = 'Team'; TierLabel["TEAM"] = TEAM;
  const ENTERPRISE = 'Enterprise'; TierLabel["ENTERPRISE"] = ENTERPRISE;
})(TierLabel || (TierLabel = {}));

export var Threshold; (function (Threshold) {
  const MAX_NUMBER_OF_TASKS_TO_LOAD = 1000; Threshold[Threshold["MAX_NUMBER_OF_TASKS_TO_LOAD"] = MAX_NUMBER_OF_TASKS_TO_LOAD] = "MAX_NUMBER_OF_TASKS_TO_LOAD";
  const AUTO_PAUSE = 2592000000; Threshold[Threshold["AUTO_PAUSE"] = AUTO_PAUSE] = "AUTO_PAUSE"; // 30 days
  const EMAIL_VERIFICATION_LIFESPAN = 86400000; Threshold[Threshold["EMAIL_VERIFICATION_LIFESPAN"] = EMAIL_VERIFICATION_LIFESPAN] = "EMAIL_VERIFICATION_LIFESPAN"; // 1 day
  const JWT_LIFESPAN = 2592000000; Threshold[Threshold["JWT_LIFESPAN"] = JWT_LIFESPAN] = "JWT_LIFESPAN"; // 30 days
  const MASS_INVITATION_TOKEN_LIFESPAN = 2592000000; Threshold[Threshold["MASS_INVITATION_TOKEN_LIFESPAN"] = MASS_INVITATION_TOKEN_LIFESPAN] = "MASS_INVITATION_TOKEN_LIFESPAN"; // 30 days
  const TEAM_INVITATION_LIFESPAN = 2592000000; Threshold[Threshold["TEAM_INVITATION_LIFESPAN"] = TEAM_INVITATION_LIFESPAN] = "TEAM_INVITATION_LIFESPAN"; // 30 days
  const FINAL_WARNING_DAYS_BEFORE_LOCK = 7; Threshold[Threshold["FINAL_WARNING_DAYS_BEFORE_LOCK"] = FINAL_WARNING_DAYS_BEFORE_LOCK] = "FINAL_WARNING_DAYS_BEFORE_LOCK";
  const MAX_FREE_TEAMS = 100; Threshold[Threshold["MAX_FREE_TEAMS"] = MAX_FREE_TEAMS] = "MAX_FREE_TEAMS";
  const MAX_ACCOUNT_PASSWORD_ATTEMPTS = 10; Threshold[Threshold["MAX_ACCOUNT_PASSWORD_ATTEMPTS"] = MAX_ACCOUNT_PASSWORD_ATTEMPTS] = "MAX_ACCOUNT_PASSWORD_ATTEMPTS";
  const MAX_ACCOUNT_DAILY_PASSWORD_RESETS = 3; Threshold[Threshold["MAX_ACCOUNT_DAILY_PASSWORD_RESETS"] = MAX_ACCOUNT_DAILY_PASSWORD_RESETS] = "MAX_ACCOUNT_DAILY_PASSWORD_RESETS";
  const MAX_AVATAR_FILE_SIZE = 1024 * 1024; Threshold[Threshold["MAX_AVATAR_FILE_SIZE"] = MAX_AVATAR_FILE_SIZE] = "MAX_AVATAR_FILE_SIZE";
  const MAX_DAILY_PASSWORD_RESETS = 5; Threshold[Threshold["MAX_DAILY_PASSWORD_RESETS"] = MAX_DAILY_PASSWORD_RESETS] = "MAX_DAILY_PASSWORD_RESETS";
  const MAX_DAILY_PASSWORD_ATTEMPTS = 100; Threshold[Threshold["MAX_DAILY_PASSWORD_ATTEMPTS"] = MAX_DAILY_PASSWORD_ATTEMPTS] = "MAX_DAILY_PASSWORD_ATTEMPTS";
  const MAX_REFLECTION_PROMPTS = 12; Threshold[Threshold["MAX_REFLECTION_PROMPTS"] = MAX_REFLECTION_PROMPTS] = "MAX_REFLECTION_PROMPTS";
  const MAX_POKER_TEMPLATE_DIMENSIONS = 12; Threshold[Threshold["MAX_POKER_TEMPLATE_DIMENSIONS"] = MAX_POKER_TEMPLATE_DIMENSIONS] = "MAX_POKER_TEMPLATE_DIMENSIONS";
  const MAX_POKER_TEMPLATE_SCALES = 12; Threshold[Threshold["MAX_POKER_TEMPLATE_SCALES"] = MAX_POKER_TEMPLATE_SCALES] = "MAX_POKER_TEMPLATE_SCALES";
  const MAX_POKER_SCALE_VALUES = 30; Threshold[Threshold["MAX_POKER_SCALE_VALUES"] = MAX_POKER_SCALE_VALUES] = "MAX_POKER_SCALE_VALUES";
  const POKER_SCALE_VALUE_MAX_LENGTH = 3; Threshold[Threshold["POKER_SCALE_VALUE_MAX_LENGTH"] = POKER_SCALE_VALUE_MAX_LENGTH] = "POKER_SCALE_VALUE_MAX_LENGTH";
  const MAX_POKER_DIMENSION_NAME = 50; Threshold[Threshold["MAX_POKER_DIMENSION_NAME"] = MAX_POKER_DIMENSION_NAME] = "MAX_POKER_DIMENSION_NAME";
  const MAX_QUAL_AI_MEETINGS = 3; Threshold[Threshold["MAX_QUAL_AI_MEETINGS"] = MAX_QUAL_AI_MEETINGS] = "MAX_QUAL_AI_MEETINGS";
  const MAX_REACTJIS = 12; Threshold[Threshold["MAX_REACTJIS"] = MAX_REACTJIS] = "MAX_REACTJIS";
  const MAX_STARTER_TIER_TEAMS = 2; Threshold[Threshold["MAX_STARTER_TIER_TEAMS"] = MAX_STARTER_TIER_TEAMS] = "MAX_STARTER_TIER_TEAMS";
  const MAX_POKER_STORIES = 50; Threshold[Threshold["MAX_POKER_STORIES"] = MAX_POKER_STORIES] = "MAX_POKER_STORIES";
  const MAX_INTEGRATION_FETCH_TIME = 10000; Threshold[Threshold["MAX_INTEGRATION_FETCH_TIME"] = MAX_INTEGRATION_FETCH_TIME] = "MAX_INTEGRATION_FETCH_TIME";
  const MIN_ACTIVE_TEAM_MEETING_ATTENDEES = 1; Threshold[Threshold["MIN_ACTIVE_TEAM_MEETING_ATTENDEES"] = MIN_ACTIVE_TEAM_MEETING_ATTENDEES] = "MIN_ACTIVE_TEAM_MEETING_ATTENDEES";
  const ACTIVE_TEAM_LAST_MEETING_TIMEFRAME = 2592000; Threshold[Threshold["ACTIVE_TEAM_LAST_MEETING_TIMEFRAME"] = ACTIVE_TEAM_LAST_MEETING_TIMEFRAME] = "ACTIVE_TEAM_LAST_MEETING_TIMEFRAME"; // 30 days
  const STARTER_TIER_LOCK_AFTER_DAYS = 30; Threshold[Threshold["STARTER_TIER_LOCK_AFTER_DAYS"] = STARTER_TIER_LOCK_AFTER_DAYS] = "STARTER_TIER_LOCK_AFTER_DAYS";
  const REFRESH_JWT_AFTER = 1296000000; Threshold[Threshold["REFRESH_JWT_AFTER"] = REFRESH_JWT_AFTER] = "REFRESH_JWT_AFTER"; // 15 days
  const RESET_PASSWORD_LIFESPAN = 86400000; Threshold[Threshold["RESET_PASSWORD_LIFESPAN"] = RESET_PASSWORD_LIFESPAN] = "RESET_PASSWORD_LIFESPAN"; // 1 day
  const VERIFY_TOKEN_LIFESPAN = 2592000000; Threshold[Threshold["VERIFY_TOKEN_LIFESPAN"] = VERIFY_TOKEN_LIFESPAN] = "VERIFY_TOKEN_LIFESPAN"; // 30 days
  const UPCOMING_INVOICE_EMAIL_WARNING = 345600000; Threshold[Threshold["UPCOMING_INVOICE_EMAIL_WARNING"] = UPCOMING_INVOICE_EMAIL_WARNING] = "UPCOMING_INVOICE_EMAIL_WARNING"; // 4 days
  const UPCOMING_INVOICE_TIME_VALID = 120000; Threshold[Threshold["UPCOMING_INVOICE_TIME_VALID"] = UPCOMING_INVOICE_TIME_VALID] = "UPCOMING_INVOICE_TIME_VALID"; // 2 minutes
})(Threshold || (Threshold = {}));

// Use these values for easier testing of team limits and usage stats
export var ThresholdTest; (function (ThresholdTest) {
  const MIN_STICKY_TEAM_MEETING_ATTENDEES = 0; ThresholdTest[ThresholdTest["MIN_STICKY_TEAM_MEETING_ATTENDEES"] = MIN_STICKY_TEAM_MEETING_ATTENDEES] = "MIN_STICKY_TEAM_MEETING_ATTENDEES";
  const MAX_STARTER_TIER_TEAMS = 0; ThresholdTest[ThresholdTest["MAX_STARTER_TIER_TEAMS"] = MAX_STARTER_TIER_TEAMS] = "MAX_STARTER_TIER_TEAMS";
  const STARTER_TIER_LOCK_AFTER_DAYS = 0; ThresholdTest[ThresholdTest["STARTER_TIER_LOCK_AFTER_DAYS"] = STARTER_TIER_LOCK_AFTER_DAYS] = "STARTER_TIER_LOCK_AFTER_DAYS";
  const STICKY_TEAM_LAST_MEETING_TIMEFRAME = 2592000; ThresholdTest[ThresholdTest["STICKY_TEAM_LAST_MEETING_TIMEFRAME"] = STICKY_TEAM_LAST_MEETING_TIMEFRAME] = "STICKY_TEAM_LAST_MEETING_TIMEFRAME";
})(ThresholdTest || (ThresholdTest = {}));

export var Times; (function (Times) { // ms
  const HUMAN_ADDICTION_THRESH = 300; Times[Times["HUMAN_ADDICTION_THRESH"] = HUMAN_ADDICTION_THRESH] = "HUMAN_ADDICTION_THRESH";
  const MAX_WAIT_TIME = 5000; Times[Times["MAX_WAIT_TIME"] = MAX_WAIT_TIME] = "MAX_WAIT_TIME";
  const MEETING_CONFIRM_TOOLTIP_DELAY = 0; Times[Times["MEETING_CONFIRM_TOOLTIP_DELAY"] = MEETING_CONFIRM_TOOLTIP_DELAY] = "MEETING_CONFIRM_TOOLTIP_DELAY";
  const MEETING_CONFIRM_DURATION = 8000; Times[Times["MEETING_CONFIRM_DURATION"] = MEETING_CONFIRM_DURATION] = "MEETING_CONFIRM_DURATION";
  const REFLECTION_DEAL_CARD_INIT_DELAY = 100; Times[Times["REFLECTION_DEAL_CARD_INIT_DELAY"] = REFLECTION_DEAL_CARD_INIT_DELAY] = "REFLECTION_DEAL_CARD_INIT_DELAY";
  const REFLECTION_DEAL_CARD_MIN_DELAY = 30; Times[Times["REFLECTION_DEAL_CARD_MIN_DELAY"] = REFLECTION_DEAL_CARD_MIN_DELAY] = "REFLECTION_DEAL_CARD_MIN_DELAY";
  const REFLECTION_DEAL_CARD_DURATION = 300; Times[Times["REFLECTION_DEAL_CARD_DURATION"] = REFLECTION_DEAL_CARD_DURATION] = "REFLECTION_DEAL_CARD_DURATION";
  const REFLECTION_DEAL_TOTAL_DURATION = 500; Times[Times["REFLECTION_DEAL_TOTAL_DURATION"] = REFLECTION_DEAL_TOTAL_DURATION] = "REFLECTION_DEAL_TOTAL_DURATION";
  const REFLECTION_COLLAPSE_DURATION = 300; Times[Times["REFLECTION_COLLAPSE_DURATION"] = REFLECTION_COLLAPSE_DURATION] = "REFLECTION_COLLAPSE_DURATION";
  const REFLECTION_DROP_DURATION = 1000; Times[Times["REFLECTION_DROP_DURATION"] = REFLECTION_DROP_DURATION] = "REFLECTION_DROP_DURATION";
  const REFLECTION_REMOTE_DROP_DURATION = 2000; Times[Times["REFLECTION_REMOTE_DROP_DURATION"] = REFLECTION_REMOTE_DROP_DURATION] = "REFLECTION_REMOTE_DROP_DURATION";
  const REFLECTION_DRAG_STALE_TIMEOUT = 10000; Times[Times["REFLECTION_DRAG_STALE_TIMEOUT"] = REFLECTION_DRAG_STALE_TIMEOUT] = "REFLECTION_DRAG_STALE_TIMEOUT";
  const REFLECTION_SPOTLIGHT_DRAG_STALE_TIMEOUT = 120000; Times[Times["REFLECTION_SPOTLIGHT_DRAG_STALE_TIMEOUT"] = REFLECTION_SPOTLIGHT_DRAG_STALE_TIMEOUT] = "REFLECTION_SPOTLIGHT_DRAG_STALE_TIMEOUT";
  const REFLECTION_COLUMN_SWIPE_THRESH = 600; Times[Times["REFLECTION_COLUMN_SWIPE_THRESH"] = REFLECTION_COLUMN_SWIPE_THRESH] = "REFLECTION_COLUMN_SWIPE_THRESH";
  const SPOTLIGHT_SOURCE_DURATION = 300; Times[Times["SPOTLIGHT_SOURCE_DURATION"] = SPOTLIGHT_SOURCE_DURATION] = "SPOTLIGHT_SOURCE_DURATION";
  const SPOTLIGHT_MODAL_DURATION = 300; Times[Times["SPOTLIGHT_MODAL_DURATION"] = SPOTLIGHT_MODAL_DURATION] = "SPOTLIGHT_MODAL_DURATION";
  const TOUCH_LONGPRESS = 120; Times[Times["TOUCH_LONGPRESS"] = TOUCH_LONGPRESS] = "TOUCH_LONGPRESS";
  const WEBSOCKET_KEEP_ALIVE = 10000; Times[Times["WEBSOCKET_KEEP_ALIVE"] = WEBSOCKET_KEEP_ALIVE] = "WEBSOCKET_KEEP_ALIVE";
  const SHOW_REACTJI_USERS_DELAY = 100; Times[Times["SHOW_REACTJI_USERS_DELAY"] = SHOW_REACTJI_USERS_DELAY] = "SHOW_REACTJI_USERS_DELAY";
})(Times || (Times = {}));

export var TrebuchetCloseReason; (function (TrebuchetCloseReason) {
  const SESSION_INVALIDATED = 'sessionInvalidated'; TrebuchetCloseReason["SESSION_INVALIDATED"] = SESSION_INVALIDATED;
  const EXPIRED_SESSION = 'expiredSession'; TrebuchetCloseReason["EXPIRED_SESSION"] = EXPIRED_SESSION;
})(TrebuchetCloseReason || (TrebuchetCloseReason = {}));
/* https://material.io/design/environment/elevation.html#default-elevations */
export var ZIndex; (function (ZIndex) {
  const BOTTOM_BAR = 8; ZIndex[ZIndex["BOTTOM_BAR"] = BOTTOM_BAR] = "BOTTOM_BAR";
  const DIALOG = 24; ZIndex[ZIndex["DIALOG"] = DIALOG] = "DIALOG";
  const FAB = 6; ZIndex[ZIndex["FAB"] = FAB] = "FAB";
  const MENU = 24; ZIndex[ZIndex["MENU"] = MENU] = "MENU";
  const SIDEBAR = 16; ZIndex[ZIndex["SIDEBAR"] = SIDEBAR] = "SIDEBAR";
  const SIDE_SHEET = 8; ZIndex[ZIndex["SIDE_SHEET"] = SIDE_SHEET] = "SIDE_SHEET";
  const SNACKBAR = 24; ZIndex[ZIndex["SNACKBAR"] = SNACKBAR] = "SNACKBAR";
  const REFLECTION_IN_FLIGHT = 8; ZIndex[ZIndex["REFLECTION_IN_FLIGHT"] = REFLECTION_IN_FLIGHT] = "REFLECTION_IN_FLIGHT";
  const REFLECTION_IN_FLIGHT_LOCAL = 28; ZIndex[ZIndex["REFLECTION_IN_FLIGHT_LOCAL"] = REFLECTION_IN_FLIGHT_LOCAL] = "REFLECTION_IN_FLIGHT_LOCAL"; // keep it above the dialog
  const REFLECTION_IN_FLIGHT_SPOTLIGHT = 26; ZIndex[ZIndex["REFLECTION_IN_FLIGHT_SPOTLIGHT"] = REFLECTION_IN_FLIGHT_SPOTLIGHT] = "REFLECTION_IN_FLIGHT_SPOTLIGHT";
  const TOOLTIP = 24; ZIndex[ZIndex["TOOLTIP"] = TOOLTIP] = "TOOLTIP"; /* portal needs to float above other components, especially sidebars */
})(ZIndex || (ZIndex = {}));

export var AuthTokenRole; (function (AuthTokenRole) {
  const SUPER_USER = 'su'; AuthTokenRole["SUPER_USER"] = SUPER_USER;
})(AuthTokenRole || (AuthTokenRole = {}));

export var SprintPokerDefaults; (function (SprintPokerDefaults) {
  const DEFAULT_TEMPLATE_ID = 'estimatedEffortTemplate'; SprintPokerDefaults["DEFAULT_TEMPLATE_ID"] = DEFAULT_TEMPLATE_ID;
  const DEFAULT_SCALE_ID = 'fibonacciScale'; SprintPokerDefaults["DEFAULT_SCALE_ID"] = DEFAULT_SCALE_ID;
  const JIRA_FIELD_DEFAULT = 'Story point estimate'; SprintPokerDefaults["JIRA_FIELD_DEFAULT"] = JIRA_FIELD_DEFAULT;
  const JIRA_FIELD_LEGACY_DEFAULT = 'Story Points'; SprintPokerDefaults["JIRA_FIELD_LEGACY_DEFAULT"] = JIRA_FIELD_LEGACY_DEFAULT;
  const AZURE_DEVOPS_USERSTORY_FIELD = '__storyPoints'; SprintPokerDefaults["AZURE_DEVOPS_USERSTORY_FIELD"] = AZURE_DEVOPS_USERSTORY_FIELD;
  const AZURE_DEVOPS_USERSTORY_FIELD_LABEL = 'Story point estimate'; SprintPokerDefaults["AZURE_DEVOPS_USERSTORY_FIELD_LABEL"] = AZURE_DEVOPS_USERSTORY_FIELD_LABEL;
  const AZURE_DEVOPS_TASK_FIELD = '__origEst'; SprintPokerDefaults["AZURE_DEVOPS_TASK_FIELD"] = AZURE_DEVOPS_TASK_FIELD;
  const AZURE_DEVOPS_TASK_FIELD_LABEL = 'Original Estimate'; SprintPokerDefaults["AZURE_DEVOPS_TASK_FIELD_LABEL"] = AZURE_DEVOPS_TASK_FIELD_LABEL;
  const AZURE_DEVOPS_REMAINING_WORK_FIELD = '__remainingWork'; SprintPokerDefaults["AZURE_DEVOPS_REMAINING_WORK_FIELD"] = AZURE_DEVOPS_REMAINING_WORK_FIELD;
  const AZURE_DEVOPS_REMAINING_WORK_LABEL = 'Remaining Work'; SprintPokerDefaults["AZURE_DEVOPS_REMAINING_WORK_LABEL"] = AZURE_DEVOPS_REMAINING_WORK_LABEL;
  const AZURE_DEVOPS_EFFORT_FIELD = '__effort'; SprintPokerDefaults["AZURE_DEVOPS_EFFORT_FIELD"] = AZURE_DEVOPS_EFFORT_FIELD;
  const AZURE_DEVOPS_EFFORT_LABEL = 'Effort'; SprintPokerDefaults["AZURE_DEVOPS_EFFORT_LABEL"] = AZURE_DEVOPS_EFFORT_LABEL;
  const SERVICE_FIELD_COMMENT = '__comment'; SprintPokerDefaults["SERVICE_FIELD_COMMENT"] = SERVICE_FIELD_COMMENT;
  const SERVICE_FIELD_COMMENT_LABEL = 'As Comment'; SprintPokerDefaults["SERVICE_FIELD_COMMENT_LABEL"] = SERVICE_FIELD_COMMENT_LABEL;
  const SERVICE_FIELD_NULL = ''; SprintPokerDefaults["SERVICE_FIELD_NULL"] = SERVICE_FIELD_NULL;
  const SERVICE_FIELD_NULL_LABEL = 'Do Not Update'; SprintPokerDefaults["SERVICE_FIELD_NULL_LABEL"] = SERVICE_FIELD_NULL_LABEL;
  const GITHUB_DEFAULT_QUERY = 'is:issue is:open sort:updated involves:@me'; SprintPokerDefaults["GITHUB_DEFAULT_QUERY"] = GITHUB_DEFAULT_QUERY;
  const JIRA_FIELD_UPDATE_ERROR = 'Couldn\'t fix the missing field! In Jira, use "Find my field" to determine the error'; SprintPokerDefaults["JIRA_FIELD_UPDATE_ERROR"] = JIRA_FIELD_UPDATE_ERROR;
  const JIRA_FIELD_UPDATE_ERROR_ESTIMATION = 'Please enable estimation feature in your Jira project settings'; SprintPokerDefaults["JIRA_FIELD_UPDATE_ERROR_ESTIMATION"] = JIRA_FIELD_UPDATE_ERROR_ESTIMATION;
  const JIRA_FIELD_UPDATE_ERROR_ESTIMATION_TIMETRACKING = 'Please enable estimation feature in your Jira project settings and add Time tracking field to the issue type'; SprintPokerDefaults["JIRA_FIELD_UPDATE_ERROR_ESTIMATION_TIMETRACKING"] = JIRA_FIELD_UPDATE_ERROR_ESTIMATION_TIMETRACKING;
  const GITLAB_FIELD_TIME_ESTIMATE = '__timeEstimate'; SprintPokerDefaults["GITLAB_FIELD_TIME_ESTIMATE"] = GITLAB_FIELD_TIME_ESTIMATE;
  const GITLAB_FIELD_TIME_ESTIMATE_LABEL = 'Time Estimate'; SprintPokerDefaults["GITLAB_FIELD_TIME_ESTIMATE_LABEL"] = GITLAB_FIELD_TIME_ESTIMATE_LABEL;
  const GITLAB_FIELD_WEIGHT = '__weight'; SprintPokerDefaults["GITLAB_FIELD_WEIGHT"] = GITLAB_FIELD_WEIGHT;
  const GITLAB_FIELD_WEIGHT_LABEL = 'Weight'; SprintPokerDefaults["GITLAB_FIELD_WEIGHT_LABEL"] = GITLAB_FIELD_WEIGHT_LABEL;
})(SprintPokerDefaults || (SprintPokerDefaults = {}));

export var AriaLabels; (function (AriaLabels) {
  const COMMENT_EDITOR = 'Comment Editor'; AriaLabels["COMMENT_EDITOR"] = COMMENT_EDITOR;
  const TASK_EDITOR = 'Task Editor'; AriaLabels["TASK_EDITOR"] = TASK_EDITOR;
})(AriaLabels || (AriaLabels = {}));

export var Polls; (function (Polls) {
  const MAX_OPTIONS = 4; Polls[Polls["MAX_OPTIONS"] = MAX_OPTIONS] = "MAX_OPTIONS";
  const MIN_OPTIONS = 2; Polls[Polls["MIN_OPTIONS"] = MIN_OPTIONS] = "MIN_OPTIONS";
  const MAX_TITLE_LENGTH = 100; Polls[Polls["MAX_TITLE_LENGTH"] = MAX_TITLE_LENGTH] = "MAX_TITLE_LENGTH";
  const MIN_TITLE_LENGTH = 2; Polls[Polls["MIN_TITLE_LENGTH"] = MIN_TITLE_LENGTH] = "MIN_TITLE_LENGTH";
  const MIN_OPTION_TITLE_LENGTH = 1; Polls[Polls["MIN_OPTION_TITLE_LENGTH"] = MIN_OPTION_TITLE_LENGTH] = "MIN_OPTION_TITLE_LENGTH";
  const MAX_OPTION_TITLE_LENGTH = 30; Polls[Polls["MAX_OPTION_TITLE_LENGTH"] = MAX_OPTION_TITLE_LENGTH] = "MAX_OPTION_TITLE_LENGTH";
})(Polls || (Polls = {}));

export var PollsAriaLabels; (function (PollsAriaLabels) {
  const POLL_TITLE_EDITOR = 'Poll Editor'; PollsAriaLabels["POLL_TITLE_EDITOR"] = POLL_TITLE_EDITOR;
  const POLL_OPTION_EDITOR = 'Poll Option Editor'; PollsAriaLabels["POLL_OPTION_EDITOR"] = POLL_OPTION_EDITOR;
  const POLL_SUBMIT_VOTE = 'Submit poll vote'; PollsAriaLabels["POLL_SUBMIT_VOTE"] = POLL_SUBMIT_VOTE;
  const POLL_START = 'Start a poll'; PollsAriaLabels["POLL_START"] = POLL_START;
  const POLL_ADD_OPTION = 'Add a poll option'; PollsAriaLabels["POLL_ADD_OPTION"] = POLL_ADD_OPTION;
})(PollsAriaLabels || (PollsAriaLabels = {}));

export var AIExplainer; (function (AIExplainer) {
  const STARTER = `AI generated summaries 🤖 are a premium feature, but we'll share a few summaries with you so you can see what they're like.`; AIExplainer[AIExplainer["STARTER"] = STARTER] = "STARTER";
  const PREMIUM_MEETING = `Our friendly AI 🤖 is here to save you time by summarizing your meeting`; AIExplainer[AIExplainer["PREMIUM_MEETING"] = PREMIUM_MEETING] = "PREMIUM_MEETING";
  const PREMIUM_REFLECTIONS = `Our friendly AI 🤖 is here to save you time by summarizing your reflections`; AIExplainer[AIExplainer["PREMIUM_REFLECTIONS"] = PREMIUM_REFLECTIONS] = "PREMIUM_REFLECTIONS";
})(AIExplainer || (AIExplainer = {}));

export var EmptyMeetingViewMessage; (function (EmptyMeetingViewMessage) {
  const NO_ACTIVE_MEETINGS = `Looks like you have no upcoming meetings 😎 Start one now or check out these tips and tricks.`; EmptyMeetingViewMessage[EmptyMeetingViewMessage["NO_ACTIVE_MEETINGS"] = NO_ACTIVE_MEETINGS] = "NO_ACTIVE_MEETINGS";
  const NO_SEARCH_RESULTS = `Sorry, we could not find any meetings that matched with your query. Would you like to checkout these tips and tricks?`; EmptyMeetingViewMessage[EmptyMeetingViewMessage["NO_SEARCH_RESULTS"] = NO_SEARCH_RESULTS] = "NO_SEARCH_RESULTS";
  const NO_ACTIVE_MEETINGS_ON_THE_TEAM = `Looks like you have no upcoming meetings on this team.`; EmptyMeetingViewMessage[EmptyMeetingViewMessage["NO_ACTIVE_MEETINGS_ON_THE_TEAM"] = NO_ACTIVE_MEETINGS_ON_THE_TEAM] = "NO_ACTIVE_MEETINGS_ON_THE_TEAM";
  const NO_SEARCH_RESULTS_ON_THE_TEAM = `Sorry, we could not find any meetings that matched with your query on this team.`; EmptyMeetingViewMessage[EmptyMeetingViewMessage["NO_SEARCH_RESULTS_ON_THE_TEAM"] = NO_SEARCH_RESULTS_ON_THE_TEAM] = "NO_SEARCH_RESULTS_ON_THE_TEAM";
})(EmptyMeetingViewMessage || (EmptyMeetingViewMessage = {}));
